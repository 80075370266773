import React from "react";
import { Layout, Menu } from "antd";
import type { MenuProps } from "antd";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { MessageOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import MemoryPage from "./pages/MemoryPage";

const { Content, Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

const items: MenuItem[] = [
  {
    key: "/chat",
    icon: <MessageOutlined />,
    label: "chat",
  },
  {
    key: "/memory",
    icon: <QuestionCircleOutlined />,
    label: "search memory",
  },
];

const App: React.FC = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();

  var navContent: any = <></>;

  const onClick: MenuProps["onClick"] = (e) => {
    navigate(e.key);
  };

  if (!isLoading) {
    navContent = (
      <Menu
        onClick={onClick}
        // style={{ width: "256px" }}
        mode="vertical"
        items={items}
      />
    );
  }

  return (
    <Layout>
      <Sider>{navContent}</Sider>
      <Content style={{ padding: "25px", height: "100vh" }}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/memory" element={<MemoryPage />} />
          <Route path="/auth/login" element={<LoginPage />} />
        </Routes>
      </Content>
    </Layout>
  );
};

export default App;
