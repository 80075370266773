import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";

const HomePage: React.FC = () => {
  const { isAuthenticated, isLoading, logout } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/auth/login" replace={true} />;
  }

  const logoutParams = { logoutParams: { returnTo: window.location.origin } };

  return (
    <div>
      <h1>Welcome to the Home Page</h1>
      <button onClick={() => logout(logoutParams)}>Logout</button>
    </div>
  );
};

export default HomePage;
