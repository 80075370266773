import React, { useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Input, Card, Col, Row } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import _ from "lodash";
import { Navigate } from "react-router-dom";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

type MemoryItem = {
  id: string;
  category: string;
  path: string;
  name: string;
  content: string;
};

const MemoryPage: React.FC = () => {
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [query, setQuery] = useState("");
  const [memoryItems, setMemoryItems] = useState<MemoryItem[] | null>(null);

  useEffect(() => {
    setMemoryItems(null);
    const controller = new AbortController();
    const timeout = setTimeout(async () => {
      if (query === "") {
        setMemoryItems(null);
        return;
      }

      const token = await getAccessTokenSilently();
      const res = await axios<MemoryItem[]>({
        signal: controller.signal,
        method: "get",
        url: "https://portal.hackberry.group/api/memory/search",
        headers: { Authorization: `Bearer ${token}` },
        params: { q: query },
      });
      setMemoryItems(res.data);
    }, 500);

    return () => {
      clearTimeout(timeout);
      controller.abort();
    };
  }, [query, getAccessTokenSilently]);

  if (isLoading) {
    return <>Loading...</>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/auth/login" replace={true} />;
  }

  return (
    <>
      <Input
        style={{ width: "100%" }}
        size="large"
        placeholder="search query"
        value={query}
        onChange={(e) => {
          setQuery(e.target.value);
        }}
        prefix={<SearchOutlined />}
      />
      <hr />
      {memoryItems === null ? (
        <span>Loading...</span>
      ) : (
        _.map(memoryItems, ({ name, path, content }) => {
          const shortened = _.truncate(content, { length: 256 });
          return (
            <Card
              style={{ margin: "10px" }}
              extra={<a href={path}>{path}</a>}
              title={name}
              bordered={true}
            >
              <Markdown remarkPlugins={[remarkGfm]}>{shortened}</Markdown>
            </Card>
          );
        })
      )}
    </>
  );
};

export default MemoryPage;
